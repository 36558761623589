import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/apex-redirect-solution",
  "date": "30 July 2021",
  "title": "Redirecting Apex Domains",
  "summary": "Apex domains cannot be associated with dynamic infrastructure due to DNS limitations. This blog outlines the service we have developed to solve this issue.",
  "author": "Nick Schuch",
  "tag": "Solution",
  "tagColor": "blue",
  "tags": [{
    "name": "solution"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`The Problem`}</h3>
    <p>{`The Skpr platform serves all web requests with CloudFront, a fast content delivery network (CDN) service that securely delivers data, videos,
applications, and APIs to customers globally with low latency and high transfer speeds.`}</p>
    <p>{`CloudFront requires that site owners use a CNAME (alias) when configuring DNS due to CloudFront's massive, `}<em parentName="p">{`dynamic`}</em>{`, `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/cloudfront/features"
      }}>{`global edge network`}</a>{`.`}</p>
    <p>{`CNAME records are becoming common as more development teams move to using CDNs which inheritantly have dynamic, fault tollerant infrastructure
which cannot have a fixed IP address for an A record.`}</p>
    <p>{`Clients want to use their Apex domains (eg. example.com) for their websites but due to a `}<a parentName="p" {...{
        "href": "https://www.isc.org/blogs/cname-at-the-apex-of-a-zone"
      }}>{`limitation with DNS`}</a>{`
a CNAME record cannot be created at this top level of the domain.`}</p>
    <h3>{`The Solution`}</h3>
    <p>{`To solve this issue we operate a service, with static IP addresses, which redirects apex domain web requests to an appropriate subdomain eg. `}<a parentName="p" {...{
        "href": "https://example.com/test"
      }}>{`https://example.com/test`}</a>{` will redirect to `}<a parentName="p" {...{
        "href": "https://www.example.com/test"
      }}>{`https://www.example.com/test`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/apex-redirect-solution/diagram.jpg",
        "alt": "Diagram demonstrating the flow of apex to subdomain traffic"
      }}></img></p>
    <p>{`This Skpr apex redirect service offering is an amalgamation of the following AWS managed services:`}</p>
    <ul>
      <li parentName="ul">{`AWS Application Load Balancer - Serves traffic and handles the redirect logic.`}</li>
      <li parentName="ul">{`AWS Global Accelerator - Provides static IP address for the ALB instance so we can use an A record.`}</li>
      <li parentName="ul">{`AWS Certificate Manager - Allows us to automatically provision certificates for clients.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      